import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-unresolved
import ResponsiveDialog from '@fathomtech/trustalice-ui-components/dist/dialogs/ResponsiveDialog/ResponsiveDialog';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Typography,
} from '@material-ui/core';
import createSubscriber from '../../utils/api/Subscribers';

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(2),
  },
  multilineColor: {
    color: 'black',
  },
  sectionTextSubtitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '22px',
      lineHeight: '1.3',
    },
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '1.5',
    marginBottom: theme.spacing(3),
    // marginTop: theme.spacing(3),
  },
}));

function SubscriberModal({
  open, handleClose,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleCloseModal = () => {
    setErrorMessage('');
    setIsEmailInvalid(false);
    setIsComplete(false);
    handleClose();
  };

  const handleSubmit = async () => {
    try {
      if (!isLoading) {
        setIsComplete(false);
        setIsLoading(true);
        setIsEmailInvalid(false);
        setErrorMessage('');
        if (!email || !validateEmail(email)) {
          setIsEmailInvalid(true);
          throw new Error('Email is invalid');
        }
        await createSubscriber({ email });
        setEmail('');
        setIsLoading(false);
        setIsComplete(true);
      }
    } catch (error) {
      console.log('error!', error);
      setIsLoading(false);
      setErrorMessage(error.message);
    }
  };

  return (
        <ResponsiveDialog
            open={open}
            handleClose={handleCloseModal}
            dialogTitle={'Find Out More'}
            handleSubmit={handleSubmit}
            isSubmitDisabled={isLoading}
        >
            {
                isComplete
                && <Alert
                    severity="success"
                    className={classes.alert}
                >
                    Thank you, we are really excited to share Trustalice with you.
                </Alert>
            }
            {
                errorMessage
                && <Alert
                    severity="error"
                    className={classes.alert}
                >
                    {errorMessage}
                </Alert>
            }
            <Typography
              className={classes.sectionTextSubtitle}
              variant="h4"
              component="h4"
            >
              We are currently in a closed Beta but will be releasing to the public soon.
              Please leave your email to be notified of further TrustAlice developments.
            </Typography>
            <TextField
                id="email"
                label="Email"
                variant="outlined"
                value={email}
                InputProps={{
                  className: classes.multilineColor,
                }}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                autoFocus
                helperText={isEmailInvalid ? 'This email is invalid' : null}
                error={isEmailInvalid}
            />
      { isLoading
        && <div className={classes.loadingDiv}>
          <CircularProgress size={60} color="primary"/>
        </div>
      }
        </ResponsiveDialog>
  );
}

SubscriberModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  dialogTitle: PropTypes.string,
  members: PropTypes.array,
  getMemberDetails: PropTypes.func,
};

export default SubscriberModal;
