import React from 'react';
import { Link } from 'gatsby';
import {
  makeStyles, Grid,
  Typography,
} from '@material-ui/core';
import TAImage from '../../../static/img/TrustAlice Wordmark White-33.svg';
import Section from '../Section';
// import Social from '../Social/Social';
import twitterWhite from '../../img/social/twitter-white.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
    flexGrow: 1,
    padding: theme.spacing(5, 0, 10, 0),
    backgroundColor: '#295BA3',
  },
  logo: {
    height: '3vh',
    marginBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  address: {
    fontSize: '16px',
    lineHeight: '180%',
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },
  email: {
    fontSize: '16px',
    lineHeight: '180%',
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '180%',
      marginBottom: theme.spacing(6),
    },
    fontSize: '16px',
    lineHeight: '160%',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
  },
  linksSection: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  link: {
    fontSize: '16px',
    lineHeight: '160%',
    marginBottom: theme.spacing(2),
    color: '#212353',
  },
  socialSection: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  socialTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    color: '#fff',
  },
  copyright: {
    fontSize: '14px',
    lineHeight: '160%',
    marginTop: theme.spacing(6),
    color: '#fff',
  },
  sectionTextTitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '1',
    },
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '1.2',
    color: '#fff',
  },
  sectionTextSubtitle: {
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      lineHeight: '1.3',
    },
    color: '#fff',
    fontWeight: 'normal',
    fontSize: '22px',
    lineHeight: '1.5',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    paddingRight: '10px',
  },
  socialsLink: {
    paddingRight: theme.spacing(4),
    width: '50%',
  },
  image: {
    maxHeight: 32,
    maxWidth: 32,
    height: 24,
    width: 24,
  },
  logoAndSocials: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      display: 'flex',
      alignContent: 'center',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <Grid
          container
          spacing={4}
          justify="center"
          alignItems="center"
        >
        <Grid item xs={12} sm={12} md={6}>
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={12} className={classes.logoAndSocials}>
            <div style={{ display: 'flex' }}>
              <Link to="/">
                <img src={TAImage} className={classes.logo} alt="TrustAlice" />
              </Link>
              <a
                title="twitter"
                href="https://twitter.com/TrustAlice1"
                rel="noopener noreferrer"
                target="_blank"
                // className={classes.socialsLink}
            >
                <img
                    className={classes.image}
                    src={twitterWhite}
                    alt="Twitter"
                />
            </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12} className={classes.socialSection}>
            <Typography className={`${classes.title} ${classes.socialTitle}`}>
              © 2022 TrustAlice. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} className={classes.linksSection}>
            <Typography className={classes.sectionTextTitle}
            >
              Contact Us
            </Typography>
            <Typography
              className={classes.sectionTextSubtitle}
              variant="h4"
              component="h4"
            >
              info@trustalice.com
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={12} className={classes.linksSection}>
          <Typography className={classes.sectionTextTitle}
              variant="h3"
              component="h3"
            >
              Explore
            </Typography>
            <div style={{ display: 'flex' }}>
            <Typography className={classes.sectionTextSubtitle} style={{ paddingRight: '10px' }}>
              Get The App
            </Typography>
            <Typography className={classes.sectionTextSubtitle}>
              Protection API
            </Typography>
            <Typography className={classes.sectionTextSubtitle}>
              About
            </Typography>
            <Typography className={classes.sectionTextSubtitle}>
              Pricing
            </Typography>
            <Typography className={classes.sectionTextSubtitle}>
              Blog
            </Typography>
            </div>
          </Grid> */}
        </Grid>
        </Grid>
        </Grid>
      </Section>
    </div>
  );
}
