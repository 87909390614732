/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Common from './Common';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/footer';

const TemplateWrapper = ({ children, className }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(() => {
      setIsLoggedIn(true);
    })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Common>
      <Navbar isLoggedIn={isLoggedIn} />
      <div className={`${className || ''}`}>{children}</div>
      <Footer />
    </Common>
  );
};

export default TemplateWrapper;
