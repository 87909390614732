import { apiRequest } from '../index';
import config from '../../config';

// console.log('config', config);
const { API } = config;

export default async function createSubscriber(body) {
  try {
    const response = await apiRequest(`${API}/subscribers/`, 'POST', body);
    // console.log('myresp', response);
    return response;
  } catch (error) {
    throw error;
  }
}
