/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SubscriberModal from '../SubscriberModal/SubscriberModal';
import logo from '../../../static/img/TrustAlice Wordmark.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      display: (props) => (props.isFullscreenNavOpen ? 'block' : 'none'),
      position: 'fixed',
      zIndex: 2,
      background: '#fff',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
    },
    display: 'none',
  },
  appBar: {
    boxShadow: 'none',
  },
  gutters: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  brand: {
    display: 'flex',
    flexGrow: 1,
  },
  logo: {
    height: 60,
    paddingBottom: 20,
    marginTop: '12px',
    [theme.breakpoints.down('sm')]: {
      height: '42px',
    },
  },
  nav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  navItem: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.contrastText,
  },
  close: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

// eslint-disable-next-line no-unused-vars
export default function FullscreenNav({
  isFullscreenNavOpen, setIsFullscreenNavOpen, handleNavHome,
}) {
  const classes = useStyles({ isFullscreenNavOpen });
  const [modalOpen, setModalOpen] = useState(false);
  const [showBusiness, setShowBusiness] = useState(true);
  const [showPersonal, setShowPersonal] = useState(true);
  const [showBlog, setShowBlog] = useState(true);

  useEffect(() => {
    if (window.location.pathname === '/business' || window.location.pathname === '/business/') {
      setShowBusiness(false);
    }
    if (window.location.pathname === '/blog' || window.location.pathname === '/blog/') {
      setShowBlog(false);
    }
    if (window.location.pathname === '/personal' || window.location.pathname === '/personal/') {
      setShowPersonal(false);
    }
  }, []);

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="transparent"
        classes={{
          root: classes.appBar,
        }}
      >
        <Toolbar
          classes={{
            gutters: classes.gutters,
          }}
        >
          <div className={classes.brand}>
            <Link>
              <img src={logo} className={classes.logo} alt="TrustAlice" onClick={() => {
                handleNavHome();
                setIsFullscreenNavOpen(false);
              }}
              />
            </Link>
          </div>
          <IconButton
            className={classes.close}
            edge="end"
            color="inherit"
            aria-label="close"
            onClick={() => setIsFullscreenNavOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <Toolbar
          classes={{
            gutters: classes.gutters,
          }}
        >
          <nav className={classes.nav}>
            {/* <a
              href="/about"
              className={classes.navItem}
            >
              <Button color="inherit">About</Button>
            </a>
            <a
              href="/discover-more"
              className={classes.navItem}
            >
              <Button color="inherit">Pricing</Button>
            </a>
                         */}
            {
              showPersonal && <a
                href="/personal"
                className={classes.navItem}
              >
                <Button >Personal</Button>
              </a>
            }
            {
              showBusiness && <a
                href="/business"
                className={classes.navItem}
              >
                <Button >Business</Button>
              </a>
            }
            {
              showBlog && <a
                href="/blog"
                className={classes.navItem}
              >
                <Button >blog</Button>
              </a>
            }
            <Button
              onClick={() => setModalOpen(true)}
              color="secondary" variant="outlined">
              Get The App
            </Button>
          </nav>
        </Toolbar>
      </AppBar>
      <SubscriberModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </div>
  );
}
