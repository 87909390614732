/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import logo from '../../../static/img/TrustAlice Wordmark White-33.svg';
import SubscriberModal from '../SubscriberModal/SubscriberModal';
import FullscreenNav from './FullscreenNav';
import BackgroundImage from '../../../static/img/TrustAlice-Background.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  appBar: {
    boxShadow: 'none',
    backgroundImage: `url(${BackgroundImage})`,
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  gutters: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(14),
      paddingRight: theme.spacing(14),
    },
  },
  brand: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexShrink: 0,
      height: '41px',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '55px',
    },
  },
  logo: {
    height: 45,
    [theme.breakpoints.down('sm')]: {
      height: '41px',
    },
    padding: theme.spacing(1, 1.5),
  },
  navItem: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    padding: theme.spacing(1, 1.5),
    color: theme.palette.primary.contrastText,
  },
  hamburger: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    zIndex: 2,
  },
  headers: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  navButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButton: {
    color: theme.palette.primary.contrastText,
    fontSize: '34px',
    justifyContent: 'left',
  },
}));

export default function Navbar({ isLoggedIn }) {
  const classes = useStyles();
  const [isFullscreenNavOpen, setIsFullscreenNavOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showBusiness, setShowBusiness] = useState(true);
  const [showPersonal, setShowPersonal] = useState(true);
  const [showBlog, setShowBlog] = useState(true);

  useEffect(() => {
    if (window.location.pathname === '/business' || window.location.pathname === '/business/') {
      setShowBusiness(false);
    }
    if (window.location.pathname === '/blog' || window.location.pathname === '/blog/') {
      setShowBlog(false);
    }
    if (window.location.pathname === '/personal' || window.location.pathname === '/personal/') {
      setShowPersonal(false);
    }
  }, []);

  const handleNavHome = () => {
    const page = localStorage.getItem('lastPage');
    navigate(page || 'personal');
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="transparent"
        classes={{
          root: classes.appBar,
        }}
      >
        <Toolbar
          classes={{
            gutters: classes.gutters,
          }}
        >
          <IconButton
            className={classes.hamburger}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={() => setIsFullscreenNavOpen(true)}
          >
            <MenuIcon className={classes.menuButton}/>
          </IconButton>
          <div className={classes.brand}>
          <Link>
              <img src={logo} className={classes.logo} alt="TrustAlice" onClick={handleNavHome}/>
            </Link>
          </div>
          {
            showPersonal && <Link
              to="/personal"
              className={classes.navItem}
            >
              <Button>Personal</Button>
            </Link>
          }
          {
            showBusiness && <Link
              to="/business"
              className={classes.navItem}
            >
              <Button>Business</Button>
            </Link>
          }
          {
            showBlog && <Link
                to="/blog"
                className={classes.navItem}
              >
                <Button>Blog</Button>
            </Link>
          }
          <Button
            size='medium'
            className={classes.navButton}
            onClick={() => setModalOpen(true)} color="primary" variant="contained">Get The App</Button>
        </Toolbar>
      </AppBar>
      <FullscreenNav
        handleNavHome={handleNavHome}
        isFullscreenNavOpen={isFullscreenNavOpen}
        setIsFullscreenNavOpen={setIsFullscreenNavOpen}
        isLoggedIn={isLoggedIn}
      />
      <SubscriberModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
      />
    </div>
  );
}
